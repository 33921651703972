<template>
    <div class="container-fluid my-3 main achat">
        <div class="row title">
            <div class="col-11">
                Achat Groupes
            </div>
            <div class="col-1 d-flex justify-content-end align-items-center">
                <i class="fa-solid fa-minus"></i>
            </div>
        </div>

        <table class="table">
            <tr>
                <td><i class="fa-solid fa-circle" style="color: #434343"></i></td>
                <td class="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat, quae.</td>
                <td class="">13-12-2024</td>
                <td><button><i class="fa-solid fa-angle-right"></i></button></td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },
    methods: {
    }
};
</script>

<style scoped>
.achat button i {
    color: #D1A34F;
    font-size: 20px;
}

.achat button {
    border: 1px solid #D1A34F;
    border-radius: 5px;
    background-color: transparent;
    padding: 5px;
}

.achat button i {
    width: 20px;
    height: 20px;
}

.achat table td {
    vertical-align: middle;
}
</style>