<template>
    <div class="container-fluid mt-4">
        <div v-if="fourniture.image">
            <div class="row mb-3">
                <div class="col">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Fournitures</a></li>
                            <!-- <li class="breadcrumb-item"><a href="#">Plomberie</a></li>
                        <li class="breadcrumb-item"><a href="#">Sanitaire</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Robinetterie</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">

                    <div class="row">
                        <div class="col-1">
                            <div class="row my-2">
                                <img src="https://placehold.co/600x400" class="product-image" alt="Product Image">
                            </div>
                            <div class="row my-2">
                                <img src="https://placehold.co/600x400" class="product-image" alt="Product Image">
                            </div>
                            <div class="row my-2">
                                <img src="https://placehold.co/600x400" class="product-image" alt="Product Image">
                            </div>
                            <div class="row my-2">
                                <img src="https://placehold.co/600x400" class="product-image" alt="Product Image">
                            </div>
                        </div>
                        <div class="col-11">
                            <img :src="require('../../assets/uploads/' + fourniture.image + '.png')"
                                :alt="fourniture.name">
                            <div class="mt-2">
                                <button class="fav btn btn-light">
                                    <i class="fa-regular fa-heart"></i>
                                    Ajouter aux Favoris
                                </button>
                                <button class="fav btn btn-light">
                                    <i class="fa-solid fa-heart"></i>
                                    Voir Liste Des Favoris
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="d-flex flex-column justify-content-between align-items-start h-100">
                        <h5>{{ fourniture.fournisseur_name }}</h5>
                        <h3>{{ fourniture.name }}</h3>
                        <h4 class="text-success">{{ fourniture.price }} USD</h4>
                        <div class="mb-3">
                            <button class="btn green-btn px-3">MARQUE</button>
                        </div>
                        <div>
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">Lorem Ipsum</button>
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">Lorem Ipsum</button>
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">Lorem Ipsum</button>
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">Lorem Ipsum</button>
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">Lorem Ipsum</button>
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">Lorem Ipsum</button>
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">Lorem Ipsum</button>
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">Lorem Ipsum</button>
                        </div>
                        <button class="btn btn-primary mb-3 w-50 green-btn">CONTACTER LE FOURNISSUER</button>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col">
                    <ul class="nav nav-tabs px-5 w-100 d-flex justify-content-between" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="description-tab" data-bs-toggle="tab"
                                data-bs-target="#description" type="button" role="tab" aria-controls="description"
                                aria-selected="true">
                                <i class="fa-solid fa-chevron-down"></i>
                                Description De Produit
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="availability-tab" data-bs-toggle="tab"
                                data-bs-target="#availability" type="button" role="tab" aria-controls="availability"
                                aria-selected="false">
                                <i class="fa-solid fa-chevron-down"></i>
                                Disponibilité
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="delivery-tab" data-bs-toggle="tab" data-bs-target="#delivery"
                                type="button" role="tab" aria-controls="delivery" aria-selected="false">
                                <i class="fa-solid fa-chevron-down"></i>
                                Livraison / Paiement
                            </button>
                        </li>
                    </ul>
                    <div class="tab-content py-4 px-4" id="myTabContent">
                        <div class="tab-pane fade show active" id="description" role="tabpanel"
                            aria-labelledby="description-tab">
                            <p>
                                Couleur(s) : Chrom/Metal<br>

                                Plus produit : Le volume et la température de l'eau peuvent être facilement ajustés
                                d'une
                                seule main. Fourni avec les tuyau flexible<br>

                                Dimensions : 185mm x 165mm <br>

                                Matière : Céramique <br>

                                Type de bec : Col de cygne <br>

                                Economi d'eau : Oui<br>

                                Finition : Chromé<br>

                                Tuyauterie / raccord fournis : Oui<br>

                                Hauteur totale : 185
                                mm<br>

                                Hauteur sous bec : 165 mm
                            </p>
                        </div>
                        <div class="tab-pane fade" id="availability" role="tabpanel" aria-labelledby="availability-tab">
                            <p>Information sur la disponibilité...</p>
                        </div>
                        <div class="tab-pane fade" id="delivery" role="tabpanel" aria-labelledby="delivery-tab">
                            <p>Information sur la livraison et le paiement...</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col">
                    <button class="btn green-btn w-100">Demander un Devis</button>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <button class="btn btn-back green-btn my-3 px-4 py-1 mx-4">
                    <i class="fa-solid fa-chevron-left"></i>
                    Retour
                </button>
            </div>
        </div>
        <div v-else></div>

    </div>
</template>

<script>

import apiService from '../../tools/apiService.js'
import { ref } from 'vue';
import { useRoute } from 'vue-router';

export default {
    data() {
        return {
            fourniture: {}
        };
    },
    mounted() {
        this.fetchFournitureByID()
    },
    methods: {
        fetchFournitureByID() {
            const route = useRoute();
            const fournitureId = ref(route.params.id).value;
            apiService.fournituresAPI.geFournitureByID(fournitureId)
                .then(response => {
                    this.fourniture = response.data
                    console.log(this.fourniture)
                })
                .catch(error => {
                    console.error('Error fetching fourniture by id:', error);
                });
        }
    }
};
</script>

<style scoped>
.green-btn {
    border: 0;
    background-color: #00371c;
    color: #fff;
    font-weight: 800;
    font-size: 20px;
}

.btn-back {
    color: #c3a566;
    font-size: 18px;
}

#description {
    font-weight: bold;
}

.nav-tabs {
    border: 0;
}

.nav-tabs .nav-item {
    width: 33%;
}

.nav-tabs button {
    background-color: rgb(175, 175, 175);
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    width: 100%;
}

.nav-tabs button.active {
    background-color: #00371c;
    color: #c3a566;
}

.tab-content {
    margin-top: -20px;
    padding-top: 50px !important;
    border: 1px solid gray;
    border-radius: 10px;
}
</style>