<template>
    <div class="container-fluid main mb-4">
        <div class="row title">
            <div class="col-11">
                <i class="fa-solid fa-bullhorn nav-icon"></i>
                Appels d'offre
            </div>
            <div class="col-1 d-flex justify-content-end align-items-center">
                <i class="fa-solid fa-minus"></i>
            </div>
        </div>
        <div class="row description-title">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum eaque
            vel explicabo ut numquam. Quod quidem corporis, laboriosam minima dicta
            deleniti consequuntur nobis. Ab perferendis neque expedita tempore! In,
            non.
        </div>
        <div class="row object">
            <label for="object">Object:</label>
            <input type="text" class="text">
        </div>
        <div class="row categorie">
            <label for="categorie">Categorie:</label>
            <div class="col-sm-2"><input type="radio" name="categorie" id="">Services</div>
            <div class="col-sm-2"><input type="radio" name="categorie" id="">Travaux</div>
            <div class="col-sm-2"><input type="radio" name="categorie" id="">Fournitures</div>
        </div>
        <div class="row description">
            <label for="">Description:</label>
            <textarea class="" name="" id="" rows="3"></textarea>
        </div>
        <div class="row budget">
            <label for="budget">Budget: <span class="circle">?</span></label>
            <input type="text" name="budget" class="text w-25">
        </div>
        <div class="row datelimit">
            <label for="datelimit">Date limite:</label>
            <span class="w-25">
                <i class="fa-solid fa-calendar-days"></i>
                <input type="date" name="datelimit">
            </span>

        </div>
        <div class="row addfile">
            <label for="addfile">Joindre Des fichers: <span class="circle">?</span></label>
            <span class="w-25">
                <i class="fa-solid fa-paperclip"></i>
                <input type="file" name="addfile" class="w-25">
            </span>
        </div>
        <div class="row mt-3">
            <div class="col d-flex justify-content-between">
                <button class="btn add "><i class="fa-solid fa-plus"></i>PUBLIER</button>
                <button class="btn cancel"><i class="fa-solid fa-x"></i>ANNULER</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },
    methods: {
    }
};
</script>

<style scoped>
.main {
    /* border-radius: 50px 0px; */
    border-radius: 10px;
    border: 1px solid gray;
    border-top: 0px;
    font-weight: bold;
}

.main .title {
    color: #00381C;
    background-color: #D1A34F;
    padding: 10px;
    padding-bottom: 7px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 25px;
    font-weight: 600;

}

.title .col-1 i {
    color: white;
    font-size: 50px;
}

.main .row:not(.title) {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.main .description-title {
    border-bottom: 1px solid gray;
}

.object input,
.categorie .col-sm-2,
.description textarea,
.budget input {
    margin-top: 20px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgb(187, 187, 187);
}

.datelimit span,
.addfile span {
    margin-top: 20px;
    border-radius: 8px;
    border: 1px solid rgb(187, 187, 187);
}

.datelimit span input,
.addfile span input {
    padding: 10px;
    border: 0px;
    width: 80%;
    border-left: 1px solid rgb(187, 187, 187);

}

.datelimit span i,
.addfile span i {
    color: #00381C;
    font-size: 35px;
    vertical-align: middle;
    padding-right: 10px;
}

.categorie .col-sm-2 {
    margin-right: 30px;
    color: gray;
}

.categorie .col-sm-2 input {
    margin-left: 10px;
    margin-right: 10px;
}

label {
    padding-left: 0 !important;
}

.btn {
    font-size: 18px;
    font-weight: 500;
    padding-left: 34px;
    padding-right: 34px;
    margin-bottom: 30px;
    text-align: center;
    width: 30%;
}

.btn.add {
    background-color: #00381C;
    color: #D1A34F;
}

.btn.cancel {
    background-color: #b60000;
    color: white;
}

.btn i {
    margin-right: 15px;
}

.circle {
    background-color: #D1A34F;
    display: inline-block;
    border-radius: 15px;
    font-size: 10px;
    width: 15px;
    height: 15px;
    text-align: center;
    vertical-align: super;
    color: white;
}

.datelimit input {
    appearance: none;
}
</style>