<template>
    <div>

        <div class="container-fluid main search my-3">
            <div class="row title">
                <div class="col-11">
                    Recherche
                </div>
                <div class="col-1 d-flex justify-content-end align-items-center">
                    <i class="fa-solid fa-minus"></i>
                </div>
            </div>
            <div class="row mt-2 mx-2">
                <div class="col-12">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste quisquam iure tempore quia ipsum a!
                </div>
            </div>
            <div class="row mt-3 mx-2">
                <div class="col-lg-5">
                    <input type="text" class="w-100" placeholder="type your search here" v-model="searchInput">
                </div>
                <div class="col-lg-4">
                    <select class="form-select" v-model="selectedCategory">
                        <option value="fourniture">Fournitures</option>
                        <option value="ptravaux">Travaux</option>
                        <option value="pservice">Services</option>
                    </select>
                </div>
                <div class="col-lg d-flex justify-content-end">
                    <button @click="navigateToRecherche" class="btn">
                        <i class="fa-solid fa-search"></i>
                        LANCER LA RECHERCHE
                    </button>
                </div>
            </div>
            <div class="row mt-3 mx-2">
                <div class="col-sm-2 d-flex align-items-center">
                    <input type="radio" class="radio" name="a"><label class="mx-2" for="a">Recherche approchee</label>
                </div>
                <div class="col-sm-2 d-flex align-items-center">
                    <input type="radio" class="radio" name="a"><label class="mx-2" for="a">Recherche exacte</label>
                </div>
            </div>
        </div>
        <!-- <div class="container-fluid my-3 main">
            <div class="row title">
                <div class="col-11">
                    <i class="fa-solid fa-envelope"></i>
                    Recherche Avancee
                </div>
                <div class="col-1 d-flex justify-content-end align-items-center">
                    <i class="fa-solid fa-minus"></i>
                </div>
            </div>
            <div class="row location">
                <div class="col-sm-4 d-flex justify-content-between align-items-center">
                    <label for="">pays: </label>
                    <input type="text" class="">
                </div>
                <div class="col-sm-4 d-flex justify-content-between align-items-center">
                    <label for="">Region: </label>
                    <input type="text" class="">
                </div>
                <div class="col-sm-4 d-flex justify-content-between align-items-center">
                    <label for="">Ville: </label>
                    <input type="text" class="">
                </div>
            </div>
            <div class="row inputs">
                <table class="table table-borderless">
                    <tr>
                        <td class="w-25">categorie Principale:</td>
                        <td><input type="text" class="w-100"></td>
                    </tr>
                    <tr>
                        <td class="w-25">Raison Social:</td>
                        <td><input type="text" class="w-100"></td>
                    </tr>
                    <tr>
                        <td class="w-25">Lots</td>
                        <td><input type="text" class="w-100"></td>
                    </tr>
                    <tr>
                        <td class="w-25">Lorem ipsum :</td>
                        <td><input type="text" class="w-100"></td>
                    </tr>
                    <tr>
                        <td class="w-25">Lorem ipsum:</td>
                        <td><input type="text" class="w-100"></td>
                    </tr>
                    <tr>
                        <td class="w-25">Prix / Budget</td>
                        <td>
                            <input type="range" value="50" min="0" max="140$" class="w-100"
                                oninput="this.nextElementSibling.value = this.value">
                            <span class="m-0 p-0">0</span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-sm d-flex justify-content-end mx-4 mb-4">
                <button @click="navigateToRecherche" class="btn btn-success">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    Lancer la recherche</button>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedCategory: null,
            searchInput: null
        };
    },
    methods: {
        navigateToRecherche() {
            if (this.searchInput && this.selectedCategory) {
                if (this.searchInput.trim() !== '') {
                    let route = '';
                    switch (this.selectedCategory) {
                        case 'fourniture':
                            route = '/search/fourniture';
                            break;
                        case 'pservice':
                            route = '/search/pservice';
                            break;
                        case 'ptravaux':
                            route = '/search/ptravaux';
                            break;
                        default:
                            return; // No navigation if no valid category selected
                    }
                    this.$router.push({ path: route, query: { search: this.searchInput } });
                }
            }
        },
    }
};
</script>

<style scoped>
.search input {
    height: 100%;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
}


.location,
.inputs {
    text-transform: uppercase;
    font-weight: 500;
}

.location {
    margin-top: 30px;
    margin-left: 2%;
    margin-right: 15%;
}

.inputs {
    margin-left: 1.4%;
    margin-right: 14.6%;
}

.location input {
    width: 85% !important;
}

.location input,
.inputs input,
select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid gray;
}

.btn {
    background-color: #00381C;
    color: #D1A34F;
    font-size: 20px;
    font-weight: 500;
    padding-left: 30px;
    padding-right: 30px;
}

.btn i {
    margin-right: 10px;
}
</style>