<template>
    <div class="container-fluid main">
        <div class="row title">
            <div class="col-11">
                <i class="fa-solid fa-envelope"></i>
                Courriel
            </div>
            <div class="col-1 d-flex justify-content-end align-items-center">
                <i class="fa-solid fa-minus"></i>
            </div>
        </div>
        <div class="row buttons">
            <div class="col">
                <button class="btn receive"><i class="fa-solid fa-angles-left"></i>Boite de reception</button>
            </div>
            <div class="col">
                <button class="btn send"><i class="fa-solid fa-angles-right"></i>Boite d'envoi</button>
            </div>
            <div class="col">
                <button class="btn newmessage" @click="openAddCourrielModal"><i class="fa-solid fa-plus"></i>Nouveau message</button>
            </div>
            <div class="col search">
                <input type="text" placeholder="type your search here" class="w-75">
                <button class="btn searchbtn"><i class="fa-solid fa-magnifying-glass"></i></button>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-11">
                <h4>Boite de reception</h4>
            </div>
            <div class="col-1"><a href=""> supprimer tout</a>
            </div>
        </div>

        <div class="row mt-3">
            <table class="table table-borderless">
                <tbody>
                    <tr>
                        <td>
                            <input type="checkbox" name="" id="">
                            <span>Expiditeur</span>
                        </td>
                        <td>
                            <h6>Objet</h6>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, dolorem!</p>
                        </td>
                        <td>
                            <p class="text-center">
                                22:10
                                <br>
                                13-12-2024
                            </p>
                        </td>
                        <td class="delete"><i class="fa-solid fa-trash-can"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="modal fade " id="add_courriel_modal" tabindex="-1" aria-labelledby="add_courriel_modal_label" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <AddCourrielModal />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { reactive } from 'vue'
import { Modal } from 'bootstrap'
import AddCourrielModal from './AddCourrielModal.vue'

export default {
    components: {
        AddCourrielModal
    },
    data() {
        return {
        };
    },
    created() {
        this.state = reactive({
            addCourrielModal: null,
        })
    },
    mounted() {
        this.state.addCourrielModal = new Modal('#add_courriel_modal', {})
    },
    methods: {
        openAddCourrielModal() {
            this.state.addCourrielModal.show()
        },
        closeAddCourrielModal() {
            this.state.addCourrielModal.hide()
        },
    }
};
</script>

<style scoped>
p,
h6 {
    margin: 0;
}

.main {
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
    border-top: 0px;
}

.main .title {
    color: #00381C;
    background-color: #D1A34F;
    padding: 10px;
    padding-bottom: 7px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 25px;
    font-weight: 600;
}

.title .col-1 i {
    color: white;
    font-size: 50px;
}

.main .buttons {
    border-bottom: 1px solid gray;
}

.main .buttons .col {
    margin-top: 20px;
    margin-bottom: 20px;
}

.buttons .btn {
    border: 1px solid rgb(187, 187, 187);
    font-size: 20px;
    font-weight: 300;
}

.buttons .btn i {
    margin-right: 10px;
}

.buttons .btn:not(.searchbtn) {
    width: 80%;
}

.buttons .receive {
    color: rgb(0, 94, 0);
    text-decoration: underline;
}

.buttons .newmessage {
    background-color: rgb(30, 173, 30);
    color: white;
    font-weight: 500;
}

.buttons .searchbtn {
    height: 100%;
    width: 10%;
    background-color: #00381C;
}

.buttons .searchbtn i {
    color: #D1A34F;
}

.buttons .search input {
    vertical-align: middle;
}

.buttons .search input {
    height: 100%;
    border-radius: 5px;
    border: 2px solid rgb(187, 187, 187);
}

.table {
    border-collapse: separate;
    border-spacing: 0px 20px;
}

.table td:not(:first-child) {
    padding: 10px;
    border-left: 2px solid rgb(187, 187, 187);
    /* text-align: center; */
}

.table td {
    background-color: rgb(230, 230, 230);
    /* vertical-align: middle; */
}

.table td:first-child {
    font-size: 20px;
    font-weight: 600;
    vertical-align: middle;
}

.table td:first-child input {
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 1px solid rgb(187, 187, 187);
    vertical-align: middle;
}

.table td:first-child span {
    display: inline-block;
    vertical-align: middle;
}

.delete {
    font-size: 25px;
    text-align: center;
    /* display: inline-block; */
    vertical-align: middle;
}

.delete i {
    color: gray;
}
</style>