<template>
    <div class="m-0 p-0">
        <NavBarComponent />

        <LoginComponent />
    </div>
</template>

<script>
import LoginComponent from "../components/auth/LoginComponent.vue"
import NavBarComponent from "../components/NavBarComponent.vue"

export default {
    components: {
        LoginComponent,
    },
    data() {
        return {
        };
    },
    methods: {
    }
};
</script>

<style>
html{
    margin: 0 !important;
    padding: 0 !important;
}
</style>