import { createStore } from "vuex";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

const store = createStore({
  state: {
    isAuthenticated: !!localStorage.getItem("aure_token"),
    user: JSON.parse(localStorage.getItem("user") || "{}"),
    token: localStorage.getItem("aure_token"),
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    user: (state) => state.user,
  },
  mutations: {
    SET_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      if (email == "admin@mail.com" && password == "password") {
        localStorage.setItem("aure_token", "data.token");
        localStorage.setItem(
          "user",
          JSON.stringify({
            name: "admin",
            email: "admin@mail.com",
            password: "password",
          })
        );

        commit("SET_TOKEN", "data.token");
        commit("SET_AUTHENTICATED", true);
        commit("SET_USER", {
          name: "admin",
          email: "admin@mail.com",
          password: "password",
        });
      }
      // await fetch("http://127.0.0.1:8081/user/login", {
      //   method: "POST",
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ email, password }),
      // })
      //   .then((response) => {
      //     if (!response.ok) {
      //       throw new Error("Invalid credentials");
      //     }
      //     return response.json();
      //   })
      //   .then((data) => {
      //     localStorage.setItem("aure_token", data.token);
      //     localStorage.setItem("user", JSON.stringify(data.user));

      //     commit("SET_TOKEN", data.token);
      //     commit("SET_AUTHENTICATED", true);
      //     commit("SET_USER", data.user);
      //     // window.location.reload();
      //   })
      //   .catch((error) => {
      //     console.error("Login failed:", error);
      //     throw error;
      //   });
    },
    async signup({ commit }, { email, password }) {
      await fetch("http://127.0.0.1:8081/register", {
        method: "POST",
        body: JSON.stringify({ email, password }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Invalid credentials");
          }
          return response.json();
        })
        .then((data) => {
          localStorage.setItem("aure_token", data.token);
          localStorage.setItem("user", JSON.stringify(data.user));

          commit("SET_TOKEN", data.token);
          commit("SET_AUTHENTICATED", true);
          commit("SET_USER", data.user);
          window.location.reload();
        })
        .catch((error) => {
          console.error("Login failed:", error);
          throw error;
        });
    },
    async logout({ commit }) {
      localStorage.removeItem("aure_token");
      localStorage.removeItem("user");
      commit("SET_AUTHENTICATED", false);
      commit("SET_USER", {});
      commit("SET_TOKEN", null);
      window.location.reload();
      // try {
      //   await fetch("http://127.0.0.1:8081/logout", {
      //     method: "POST",
      //   })
      //     .then((response) => {
      //       if (!response.ok) {
      //         throw new Error("Invalid credentials");
      //       }
      //       return response.json();
      //     })
      //     .then(() => {
      //       localStorage.removeItem("aure_token");
      //       localStorage.removeItem("user");
      //       commit("SET_AUTHENTICATED", false);
      //       commit("SET_USER", {});
      //       commit("SET_TOKEN", null);
      //       window.location.reload();
      //     })
      //     .catch((error) => {
      //       console.error("Login failed:", error);
      //       throw error;
      //     });
      // } catch (error) {
      //   console.error("Logout failed:", error);
      //   throw error;
      // }
    },
  },
  modules: {},
});

export default store;

export const authGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const isAuthenticated = store.getters.isAuthenticated;

  const user = store.state.user;

  if (!isAuthenticated) {
    if (to.name !== "login" && to.name !== "signup") {
      next({ name: "login" });
    }
  }

  next();
};
