<template>
  <div>
        <NavBarComponent />
        <div class="mt-4">
            <NavBarRootesComponent />
        </div>
        <div class="mt-4 mx-3">
            <NotificationComponents />
        </div>
        <FooterComponent />
    </div>
</template>

<script>
// Importing the components
import NavBarComponent from '../components/NavBarComponent.vue'
import FooterComponent from '../components/FooterComponent.vue'
import NotificationComponents from '../components/notification/NotificationComponents.vue'
import NavBarRootesComponent from '../components/NavBarRootesComponent.vue'


export default {
    components: {
        NavBarComponent,
        FooterComponent,
        NotificationComponents,
        NavBarRootesComponent
    },
}
</script>

<style scoped>


</style>