// Import Axios
import axios from "axios";

// Define base API URL
// const baseURL = "http://127.0.0.1:8000";
const baseURL = "https://backend.aureght.com";

// Set up Axios instance
const axiosInstance = axios.create({ baseURL });

// // Import the Vuex store
// import store from '@/store';

// Auth
const authAPI = {
  login: (credentials) => axiosInstance.post("login", credentials),
  signup: (credentials) => axiosInstance.post("signup", credentials),
  logout: () => axiosInstance.post("logout"),
};

// Fournitures
const fournituresAPI = {
  getAllCategories: () => axiosInstance.get("fourniture/categories"), //
  getAllFournitures: () => axiosInstance.get("fournitures"), //
  geFournitureByID: (fournitureID) =>
    axiosInstance.get(`fournitures/${fournitureID}`), //
};

// Travaux
const travauxAPI = {
  getAllTravaux: () => axiosInstance.get(`travaux`), //
  getPTravauxByID: (travauxID) =>
    axiosInstance.get(`prestataire/travaux/${travauxID}`), //
  getAllPTravaux: () => axiosInstance.get(`prestataire/travaux`),
};

// Service
const servicesAPI = {
  getAllCategories: () => axiosInstance.get("services"),
  getPServiceByID: (serviceID) =>
    axiosInstance.get(`prestataire/service/${serviceID}`),

  getAllServices: () => axiosInstance.get(`services`), //
  getAllPService: () => axiosInstance.get(`prestataire/services`),
};

export default { authAPI, fournituresAPI, travauxAPI, servicesAPI };
