<template>
    <div class="container-fluid my-3">
        <div class="row">
            <div class="mt-2 col-lg-4" v-for="(category, index) in categories" :key="index">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">{{ category.title }}</h5>
                        <p class="card-text description">
                            {{ category.description }}
                        </p>
                        <div class="row">
                            <Carousel :items-to-show="3" :wrap-around="true">
                                <Slide v-for="(item, itemIndex) in category.items" :key="itemIndex" class="col-sm-4">
                                    <div class="carousel__item card categorie">
                                        <div
                                            class="card-body d-flex flex-column justify-content-between align-items-center">
                                            <div class="text-center">
                                           

                                                <img :src="require('../../assets/uploads/' + item.icon + '.png')" alt=""
                                                    @click="navigateToSearch(category.title)"> 
                                                <!-- <img src="../../assets/img/auth/icon.png" alt=""
                                                    @click="navigateToSearch(category.title)"> -->
                                            </div>
                                            <div class="text-center">
                                                <p class="card-title" @click="navigateToRecherche">{{
                                                    truncateText(item.name) }}</p>
                                                <div class="card-text"></div>
                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                                <template #addons>
                                    <Navigation />
                                </template>
                            </Carousel>
                        </div>
                        <p class="all-categories">TOUTES LES CATEGORIES</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

import apiService from '../../tools/apiService.js'

export default {
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            categories: []
        };
    },
    mounted() {
        this.fetchCategories();
    },
    methods: {
        navigateToSearch(categoryTitle) {
            switch (categoryTitle) {
                case "Fournitures":
                    this.navigateToSearchFourniture()
                    break;

                case "Travaux":
                    this.navigateToSearchPTravaux()
                    break;

                case "Service":
                    this.navigateToSearchPService()
                    break;

                default:
                    break;
            }
        },
        navigateToSearchFourniture() {
            this.$router.push('/search/fourniture');
        },
        navigateToSearchPTravaux() {
            this.$router.push('/search/ptravaux');
        },
        navigateToSearchPService() {
            this.$router.push('/search/pservice');
        },
        truncateText(text) {
            return text.length > 10 ? text.substring(0, 10) + '...' : text;
        },
        fetchCategories() {
            apiService.fournituresAPI.getAllCategories()
                .then(response => {
                    this.categories.push(
                        {
                            title: 'Fournitures',
                            description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
                            items: response.data
                        }
                    );
                })
                .catch(error => {
                    console.error('Error fetching f_categories:', error);
                });

            apiService.travauxAPI.getAllTravaux()
                .then(response => {
                    console.log(response)
                    this.categories.push(
                        {
                            title: 'Travaux',
                            description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
                            items: response.data
                        }
                    );
                })
                .catch(error => {
                    console.error('Error fetching f_categories:', error);
                });

            apiService.servicesAPI.getAllCategories()
                .then(response => {
                    this.categories.push(
                        {
                            title: 'Service',
                            description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
                            items: response.data
                        }
                    );
                })
                .catch(error => {
                    console.error('Error fetching f_categories:', error);
                });
        },
    }
};
</script>

<style scoped>
.description {
    border-bottom: 1px solid #c5c5c5;
    padding-bottom: 20px;
    margin-bottom: 40px;
}

.all-categories {
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
    color: #5f5f5f;
    font-weight: 500;
}

.card-text {
    font-size: 13px;
    text-align: justify;
}

.categorie {
    border-radius: 15px;
    min-height: 180px;
    font-size: 15px;
    margin-right: 10px;
}

.categorie img {
    margin-top: 15%;
    background-color: #f1f1f1;
    border-radius: 50%;
    width: 60%;
    cursor: pointer;
    /* padding: 10%; */
}

.categorie .card-title {
    margin-bottom: 0;
    cursor: pointer;
    /* Remove default margin */
}
</style>