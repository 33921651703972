<template>
    <div class="container-fluid main">
        <div class="row title">
            <div class="col-11">
                <i class="fa-solid fa-bullhorn nav-icon"></i>
                Appels d'offre
            </div>
            <div class="col-1 d-flex justify-content-end align-items-center">
                <i class="fa-solid fa-minus"></i>
            </div>
        </div>

        <div class="row m-2 mt-3">
            <table class="table table-borderless">
                <thead>
                    <tr>
                        <th>Objet</th>
                        <th>categorie</th>
                        <th>Consultation</th>
                        <th>offres</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="first">
                        <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, quam!</td>
                        <td>Service</td>
                        <td>45</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, quam!</td>
                        <td>Service</td>
                        <td>45</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, quam!</td>
                        <td>Service</td>
                        <td>45</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, quam!</td>
                        <td>Service</td>
                        <td>45</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, quam!</td>
                        <td>Service</td>
                        <td>45</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, quam!</td>
                        <td>Service</td>
                        <td>45</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, quam!</td>
                        <td>Service</td>
                        <td>45</td>
                        <td>2</td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="row m-4">
            <div class="col d-flex justify-content-end">
                <button class="btn" @click="openAddCourrielModal"><i class="fa-solid fa-plus"></i> NV APPEL D'OFFRE</button>
            </div>
        </div>
        <div class="modal fade " id="add_appel_offre_modal" tabindex="-1" aria-labelledby="add_courriel_modal_label"
            aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <AddAppelOffreModal />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { reactive } from 'vue'
import { Modal } from 'bootstrap'
import AddAppelOffreModal from './AddAppelOffreModal.vue'


export default {
    components: {
        AddAppelOffreModal
    },
    data() {
        return {
        };
    },
    created() {
        this.state = reactive({
            addAppelOffre: null,
        })
    },
    mounted() {
        this.state.addAppelOffre = new Modal('#add_appel_offre_modal', {})
    },
    methods: {
        openAddCourrielModal() {
            this.state.addAppelOffre.show()
        },
        closeAddCourrielModal() {
            this.state.addAppelOffre.hide()
        },
    }
};
</script>

<style scoped>
.main {
    /* border-radius: 50px 0px; */
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
    border-top: 0px;
}

.main .title {
    color: #00381C;
    background-color: #D1A34F;
    padding: 10px;
    padding-bottom: 7px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 25px;
    font-weight: 600;
}

.title .col-1 i {
    color: white;
    font-size: 50px;
}

.table th {
    color: white;
    padding: 12px;
    font-size: 20px;
    /* border-left: 2px solid white; */
}

.table thead tr th {
    background-color: rgb(187, 187, 187);
}

.table {
    border-collapse: separate;
    border-spacing: 0px 20px;
}

.table td {
    padding: 20px;
    color: gray;
    border: 1px solid rgb(187, 187, 187);
    /* text-align: center; */
}

tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

tr.first td:first-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 10px;
}

tr.first td:last-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 10px;
}

tr th:first-child {
    border-top-left-radius: 10px;
}

tr th:last-child {
    border-top-right-radius: 10px;
}

tr td:not(:first-child) {
    text-align: center;
}

tr th:not(:first-child) {
    text-align: center;
    border-left: 1px solid white;
}

.btn {
    background-color: #00381C;
    color: #D1A34F;
    font-size: 22px;
    font-weight: 500;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 30px;
}
</style>
