<template>
    <div class="container-fluid pub">
        <div class="row mb-3">
            <Carousel :items-to-show="1.5" :wrap-around="true" :autoplay="4000">
                <Slide class="mt-2 mx-4 col-xl-8" key="1">
                    <div class="carousel__item card first">
                        <p>
                            Espace publicitaire
                        </p>
                    </div>
                </Slide>
                <Slide class="mt-2 mx-4 col-xl-8" key="2">
                    <div class="carousel__item card second">
                        <p>
                            Espace publicitaire
                        </p>
                    </div>
                </Slide>
                <template #addons>
                    <Navigation />
                </template>
            </Carousel>
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
    data() {
        return {
        };
    },
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    methods: {
    }
};
</script>

<style scoped>
.pub .card.first {
    background-image: url('../../assets/img/auth/bg-3.jpg');
}

.pub .card.second {
    background-image: url('../../assets/img/auth/bg-1.jpg');
}

.pub .card {
    width: 100%;
    height: 300px;
    border-radius: 15px;
    background-size: cover;
}

.pub .card p {
    padding: 5px;
    width: 200px;
    margin-left: 40px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
</style>