<template>

    <nav class="navbar navbar-expand-lg authnavbar">
        <div class="container-fluid">
            <router-link to="/" class="navbar-brand">
                <img src="../assets/svg/logo.svg" alt="" width="180" />
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mainAuthNavBar"
                aria-controls="mainAuthNavBar" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="mainAuthNavBar">
                <ul class="navbar-nav me-auto d-flex justify-conteny-center align-items-center">
                    <h2 class="title d-flex justify-conteny-center align-items-center">
                        <i class="fa-solid fa-circle mx-4"></i>
                        <span>
                            Central D'achat
                        </span>
                    </h2>
                    <h5 class="d-flex justify-conteny-center align-items-center">
                        <span>
                            {{ role }}
                        </span>
                    </h5>
                </ul>
                <div>
                    <ul class="navbar-nav mb-2 mb-lg-0" id="lastNav">
                        
                        <li class="nav-item d-flex">
                            <div class="d-inline-flex align-items-center">
                                <img src="../assets/svg/email.svg" alt="" width="25" />
                                <img src="../assets/svg/phone.svg" class="mx-2" alt="" width="25" />
                                <img src="../assets/svg/address.svg" width="25" alt="" />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>

</template>


<script>
import { reactive } from 'vue'

export default {
    data() {
        return {
            role: ""
        };
    },
    created() {
        this.state = reactive({
            loginModal: null,
        })
    },
    methods: {
        openLoginModal() {
            this.state.loginModal.show()
        },
        closeLoginModal() {
            this.state.loginModal.hide()
        },
    }
};
</script>

<style scoped>
@import '../assets/css/nav.css';

.fa-user {
    font-size: 25px;
    color: white;
}

#account {
    cursor: pointer;
}

.authnavbar {
    background-color: #00371c;
    height: 100px;
    color: #fff;
}

.authnavbar h2,
.authnavbar h5 {
    margin-left: 30px;
}

.authnavbar h2,
.authnavbar h5 {
    border-left: 1px solid #727272;
    padding-left: 40px;
    padding-right: 40px;
    height: 80px;
}

.title i{
color: #D1A34F;
}
</style>