<template>
    <div class="ptravaux-page container-fluid mt-4">
        <div v-if="pTravaux">

            <div class="row mb-3">
                <div class="col">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">PRESTATAIRE DE TRAVAUX</a></li>
                            <!-- <li class="breadcrumb-item active"><a href="#">BUREAU D"ARCHITECTE</a></li> -->
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="image px-3 py-3 m-4">
                        <div v-if="pTravaux.logo">
                            <img class="w-100" :src="require('../../assets/uploads/' + pTravaux.logo + '.png')"
                                :alt="pTravaux.name">
                        </div>

                        <!-- <img src="https://placehold.co/600x400" class="product-image w-100" alt="Product Image"> -->
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="d-flex flex-column justify-content-between align-items-start h-100">
                        <h3>{{ pTravaux.name }}</h3>
                        <p>
                            {{ pTravaux.description }}
                        </p>
                        <div class="w-75">
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">{{ pTravaux.activity }}</button>
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">{{ pTravaux.activity }}</button>
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">{{ pTravaux.activity }}</button>
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">{{ pTravaux.activity }}</button>
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">{{ pTravaux.activity }}</button>
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">{{ pTravaux.activity }}</button>
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">{{ pTravaux.activity }}</button>
                            <button class="mx-2 px-4 my-2 btn btn-outline-secondary">{{ pTravaux.activity }}</button>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row mt-4">
                <div class="col">
                    <ul class="nav nav-tabs px-5 w-100 d-flex justify-content-between" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="description-tab" data-bs-toggle="tab"
                                data-bs-target="#description" type="button" role="tab" aria-controls="description"
                                aria-selected="true">
                                <i class="fa-solid fa-chevron-down"></i>
                                Portfolio
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="availability-tab" data-bs-toggle="tab"
                                data-bs-target="#availability" type="button" role="tab" aria-controls="availability"
                                aria-selected="false">
                                <i class="fa-solid fa-chevron-down"></i>
                                Informations
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="delivery-tab" data-bs-toggle="tab" data-bs-target="#delivery"
                                type="button" role="tab" aria-controls="delivery" aria-selected="false">
                                <i class="fa-solid fa-chevron-down"></i>
                                Contacts
                            </button>
                        </li>
                    </ul>
                    <div class="tab-content py-4 px-4" id="myTabContent">
                        <div class="tab-pane fade show active" id="description" role="tabpanel"
                            aria-labelledby="description-tab">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-7">
                                        <div v-if="pTravaux.logo">

                                            <img class="product-image w-100 mb-4"
                                                :src="require('../../assets/uploads/' + pTravaux.logo + '.png')"
                                                :alt="pTravaux.name">
                                        </div>
                                        <!-- <img src="https://placehold.co/600x400" class="product-image w-100 mb-4"
                                        alt="Product Image"> -->
                                        <Carousel :items-to-show="3" :wrap-around="false">
                                            <Slide class="col-sm-4 mx-2">
                                                <div class="carousel__item card categorie">
                                                    <div class="card-body ">
                                                        <img src="https://placehold.co/600x400" class="w-100"
                                                            alt="Product Image">
                                                    </div>
                                                </div>
                                            </Slide>
                                            <Slide class="col-sm-4 mx-2">
                                                <div class="carousel__item card categorie">
                                                    <div class="card-body ">
                                                        <img src="https://placehold.co/600x400" class="w-100"
                                                            alt="Product Image">
                                                    </div>
                                                </div>
                                            </Slide>
                                            <Slide class="col-sm-4 mx-2">
                                                <div class="carousel__item card categorie">
                                                    <div class="card-body ">
                                                        <img src="https://placehold.co/600x400" class="w-100"
                                                            alt="Product Image">
                                                    </div>
                                                </div>
                                            </Slide>
                                            <template #addons>
                                                <Navigation />
                                            </template>
                                        </Carousel>
                                    </div>
                                    <div class="col-lg-5">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. At esse, quisquam
                                        perspiciatis,
                                        mollitia maiores inventore laudantium ducimus molestias quos ipsum, optio itaque
                                        sapiente aliquid nostrum! Iste cum molestias quas accusantium!
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="availability" role="tabpanel" aria-labelledby="availability-tab">
                            <p>Informations</p>
                        </div>
                        <div class="tab-pane fade" id="delivery" role="tabpanel" aria-labelledby="delivery-tab">
                            <p>Contacts</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col">
                    <button class="btn green-btn w-100">Demander un Devis</button>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <button class="btn btn-back green-btn my-3 px-4 py-1 mx-4">
                    <i class="fa-solid fa-chevron-left"></i>
                    Retour
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import apiService from '../../tools/apiService.js'
import { ref } from 'vue';
import { useRoute } from 'vue-router';

export default {
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            pTravaux: null
        };
    },
    mounted() {
        this.fetchPTravauxByID()
    },
    methods: {
        fetchPTravauxByID() {
            const route = useRoute();
            const travauxId = ref(route.params.id).value;
            apiService.travauxAPI.getPTravauxByID(travauxId)
                .then(response => {
                    console.log(response)
                    this.pTravaux = response.data
                })
                .catch(error => {
                    console.error('Error fetching fourniture by id:', error);
                });
        }
    }
};
</script>

<style scoped>
.green-btn {
    border: 0;
    background-color: #00371c;
    color: #fff;
    font-weight: 800;
    font-size: 20px;
}

.btn-back {
    color: #c3a566;
    font-size: 18px;
}

#description {
    font-weight: bold;
}

.nav-tabs {
    border: 0;
}

.nav-tabs .nav-item {
    width: 33%;
}

.nav-tabs button {
    background-color: rgb(175, 175, 175);
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    width: 100%;
}

.nav-tabs button.active {
    background-color: #00371c;
    color: #c3a566;
}

.tab-content {
    margin-top: -20px;
    padding-top: 50px !important;
    border: 1px solid gray;
    border-radius: 10px;
}

.image {
    border: 1px solid gray;
    border-radius: 20px;
}

.ptravaux-page .carousel__item {
    height: 250px !important;
}
</style>