<template>
  <div>
        <NavBarComponent />
        <div class="mt-4">
            <NavBarRootesComponent />
        </div>
        <div class="my-4 mx-3">
            <AdsComponent />

            <CategoriesComponent />

            <SearchComponent />

            <PromotionsComponent />

            <GroupPurchases />
        </div>
    </div>
</template>

<script>

import NavBarComponent from '../components/NavBarComponent.vue'
import NavBarRootesComponent from '../components/NavBarRootesComponent.vue'
import AdsComponent from '../components/dashboard/AdsComponent.vue'
import CategoriesComponent from '../components/dashboard/CategoriesComponent.vue'
import GroupPurchases from '../components/dashboard/GroupPurchases.vue'
import PromotionsComponent from '../components/dashboard/PromotionsComponent.vue'
import SearchComponent from '../components/dashboard/SearchComponent.vue'

export default {
    components: {
        NavBarComponent,
        NavBarRootesComponent,
        AdsComponent,
        CategoriesComponent,
        GroupPurchases,
        PromotionsComponent,
        SearchComponent,
    },
    data() {
        return {
            fournitureCategories: [
                { imgSrc: require('../assets/img/auth/icon.png'), title: 'Coffrage' },
                { imgSrc: require('../assets/img/auth/icon.png'), title: 'Coffrage' },
                { imgSrc: require('../assets/img/auth/icon.png'), title: 'Coffrage' }
            ],
            travauxCategories: [
                { imgSrc: require('../assets/img/auth/icon.png'), title: 'Coffrage' },
                { imgSrc: require('../assets/img/auth/icon.png'), title: 'Coffrage' },
                { imgSrc: require('../assets/img/auth/icon.png'), title: 'Coffrage' }
            ],
            serviceCategories: [
                { imgSrc: require('../assets/img/auth/icon.png'), title: 'Coffrage' },
                { imgSrc: require('../assets/img/auth/icon.png'), title: 'Coffrage' },
                { imgSrc: require('../assets/img/auth/icon.png'), title: 'Coffrage' }
            ]
        };
    },
}
</script>

<style>
.main {
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
    border-top: 0px;
}

.main .title {
    color: #00381C;
    background-color: #D1A34F;
    padding: 10px;
    padding-bottom: 7px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 25px;
    font-weight: 600;
}

.title .col-1 i,
.title .col-sm-6 i {
    color: white;
    font-size: 50px;
}

.table {
    border-collapse: separate;
    border-spacing: 0px 10px;
}
</style>