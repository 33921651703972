<template>
    <nav class="navbar navbar-expand-xxl" v-if="isAuth">
        <div class="container-fluid">
            <div class="w-100 d-flex justify-content-between">

                <div class="navbar-brand">
                    <a  href="/">
                        <img class="p-2" src="../assets/img/auth/african-unoin.png" alt="">
                    </a>
                    <span class="id">
                        PI_23567804MA
                    </span>
                </div>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mainAuthNavBar"
                    aria-controls="mainAuthNavBar" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="mainAuthNavBar">

                    <ul class="navbar-nav me-auto">
                        <li class="nav-item">
                            <a class="nav-link" :class="$route.path == '/' ? 'current' : ''" href="/"><i
                                    class="fa-solid fa-house nav-icon"></i>Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="$route.path == '/notification' ? 'current' : ''"
                                href="/notification"><i class="fa-solid fa-bell nav-icon"></i>Notification <span
                                    class="circle">1</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="$route.path == '/courriel' ? 'current' : ''" href="/courriel"><i
                                    class="fa-solid fa-envelope nav-icon"></i>Couriel <span class="circle">5</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="$route.path == '/appeloffre' ? 'current' : ''"
                                href="/appeloffre"><i class="fa-solid fa-bullhorn nav-icon"></i>Appels
                                d'offre</a>
                        </li>
                    </ul>
                    <div class="settings">
                        <button class="btn" @click="logout">
                            <i class="fa-solid fa-arrow-right-from-bracket"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </nav>
    <nav class="navbar navbar-expand-xxl unauth" v-else>
        <div class="container-fluid">
            <div class="row w-100">
                <div class="col-lg-11 d-flex justify-content-center align-items-center title">
                    <span class="">
                        CENTRAL D'ACHAT
                    </span>
                </div>
                <div class="col-lg-1 d-flex justify-content-center align-items-center">
                    <button>
                        <i class="fa-solid fa-right-from-bracket"></i>
                    </button>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>

export default {
    data() {
        return {
            isAuth: true
        };
    },
    methods: {
        logout(){
            this.$store.dispatch('logout')
        }
    }
};
</script>

<style scoped>
.unauth .title {
    color: #fff;
    font-size: 35px;
    font-weight: 500;
}

.unauth .title:after {
    content: "";
    display: inline-block;
    height: 0.9em;
    width: 35%;
    margin-top: 1.5vw;
    margin-left: 2vw;
    border-top: 2px solid #caa45d;
}

.unauth .title:before {
    content: "";
    display: inline-block;
    height: 0.9em;
    width: 35%;
    margin-top: 1.5vw;
    margin-right: 2vw;
    border-top: 2px solid #caa45d;
}

.unauth button {
    background-color: transparent;
    border: 0;
    color: #caa45d;
    font-size: 35px;
}

.navbar {
    background-image: url("../assets/img/auth/BG-MENU.png");
    background-size: cover;
    min-height: 120px;
    width: 100%;
    border-radius: 10px;
    padding-left: 50px;
    padding-right: 50px;
    justify-content: space-between;
    /* Align items horizontally */
}

a.nav-link {
    color: white;
    font-size: 20px;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 10px;
    text-align: center;
    vertical-align: middle;
}

li.nav-item:not(:first-child) .nav-link{
    border-left: 2px solid white;
}

.nav-link i.nav-icon {
    margin-right: 10px;
    margin-left: 10px;
}

.nav-link span.circle {
    background-color: green;
    display: inline-block;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
}

.nav-item {
    display: flex;
    align-items: center;
    /* Center vertically */
}

.navbar-brand a {
    text-decoration: none;
    color: transparent;
}


.navbar-brand img {
    width: 90px;
    height: 93px;
    border-radius: 50%;
    border: 2px solid white;
}

.navbar-brand .id {
    color: white;
    border-radius: 5px;
    font-size: 17px;
    border: 1px solid rgba(202, 164, 93, .5);
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-left: 20px;
}

.settings i {
    color: white;
    font-size: 30px;
}

.nav-item .current {
    color: #D1A34F;
}
</style>