<template>
    <div>
        <div class="container-fluid main m-0 p-0">
            <div class="row title">
                <div class="col">
                    <i class="fa-solid fa-circle nav-icon"></i>
                    CENTRALE D'ACHAT
                </div>
            </div>
            <div class="row form">
                <div class="container">
                    <form @submit.prevent="signIn">
                        <div class="row w-100 h-100 d-flex justify-content-center align-items-center">
                            <div class="col-xl-5">
                                <p>Email</p>
                                <input v-model="formData.email" name="email" type="email" placeholder="Email">
                                <p>Access Perdu?</p>
                            </div>
                            <div class="col-xl-5">
                                <p>Mot de passe</p>
                                <input v-model="formData.password" name="password" type="password" placeholder="passe">
                                <p>Mot de pass oublié?</p>
                            </div>
                            <div class="col-xl-2 cursor-pointer" @click="signIn">
                                <p>&nbsp;</p>
                                <button><i class="fa-solid fa-right-to-bracket"></i></button>
                                <p>se connecter</p>
                            </div>
                        </div>
                    </form>
                    <div class="row links">
                        <div class="col">Aide</div>
                        <div class="col">Règles de confidentialité</div>
                        <div class="col">Condition d'utilisation</div>
                    </div>
                </div>
            </div>
            <div class="row info">
                <div class="col-2"><img src="../../assets/img/auth/Aure.png" alt=""></div>
                <div class="col-10">
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book. It has survived not
                    only five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages,
                    and more recently with desktop publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum.
                </div>
            </div>
            <div class="row signup">
                <div class="col-10">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                <div class="col-2">
                    <button @click="goToSignUp">Demande d'adhèsion</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            formData: {
                email: '',
                password: '',
            },
        };
    },
    methods: {
        async signIn() {
            this.$store.dispatch('login', {
                email: this.formData.email,
                password: this.formData.password
            }).then(() => {
                this.$router.push('/')
            }).catch(error => {
                console.error('Login failed:', error);
            });
        },
        goToSignUp() {
            // this.$router.push('/signup')
        }
    }
};
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

p {
    margin: 0;
    padding: 0;
}

.main {
    font-weight: bold;
}

.main .title {
    color: #FFF;
    background-color: #00381C;
    border-radius: 0;
    padding: 20px;
    font-size: 30px;
    font-weight: 600;
}

.title i {
    color: #D1A34F;
    margin-left: 10px;
    margin-right: 20px;
}

.title .col-1 i {
    font-size: 40px;
}

.main .form {
    height: 800px;
    background-image: url('../../assets/img/auth/BG-01-LOGIN.png');
    background-size: cover;
    color: #fff;
    padding: 20px;
    color: rgb(187, 187, 187);
}

.main .form form {
    height: 90%;
    width: 90%;
    margin-left: 10%;
    margin-right: 10%;
}

.main form input {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid rgb(187, 187, 187);
    margin-top: 10px;
    margin-bottom: 10px;
}

.main form button {
    background-color: #00381C;
    border: 0px;
    border-radius: 5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.main form button i {
    color: white;
    font-size: 30px;
}

.main .links {
    text-align: center;
}

.info {
    background-color: #00381C;
    color: rgb(187, 187, 187);
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #5e5e5e;
}

.info img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

.info .col-10 {
    padding: 20px;
    text-align: justify;
    padding-right: 30px;
}

.signup {
    background-color: #00381C;
    color: rgb(187, 187, 187);
    text-align: right;
    padding: 20px;
    padding-right: 30px;
}

.signup button {
    color: #00381C;
    font-size: 20px;
    font-weight: 700;
    border-radius: 20px;
    border: 1px solid transparent;
    background-color: #D1A34F;
}
</style>