<template>
    <div class="container-fluid main">
        <div class="row title">
            <div class="col-11">
                <i class="fa-solid fa-envelope"></i>
                Notification
            </div>
            <div class="col-1 d-flex justify-content-end align-items-center">
                <i class="fa-solid fa-minus"></i>
            </div>
        </div>
        <div class="row mt-4 d-flex justify-content-end search">
            <div class="col-4 d-flex justify-content-end">
                <input type="text" placeholder="type your search here" class="w-75">
                <button class="btn searchbtn"><i class="fa-solid fa-magnifying-glass"></i></button>
            </div>
        </div>
        <div class="row mt-3 content">
            <table class="table table-borderless">
                <tr class="selected">
                    <td><i class="fa-solid fa-circle"></i></td>
                    <td>
                        <h6>enquete Satisfaction central Achat</h6>
                        <p>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                            Veritatis, deserunt.
                        </p>
                    </td>
                    <td class="date">
                        <p>13-12-2024</p>
                    </td>
                    <td class="delete"><i class="fa-solid fa-trash-can"></i></td>
                </tr>
                <tr class="">
                    <td><i class="fa-solid fa-circle"></i></td>
                    <td>
                        <h6>enquete Satisfaction central Achat</h6>
                        <p>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                            Veritatis, deserunt.
                        </p>
                    </td>
                    <td class="date">
                        <p>13-12-2024</p>
                    </td>
                    <td class="delete"><i class="fa-solid fa-trash-can"></i></td>
                </tr>
            </table>
        </div>
        <div class="row delete-all">
            <a href="#" class="d-flex justify-content-end">Supprimer tout</a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },
    methods: {
    }
};
</script>

<style scoped>
p,
table {
    padding: 0;
    margin: 0;
}

.main {
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
    border-top: 0px;
}

.main .title {
    color: #00381C;
    background-color: #D1A34F;
    padding: 10px;
    padding-bottom: 7px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 25px;
    font-weight: 600;
}

.title .col-1 i {
    color: white;
    font-size: 50px;
}

.search {
    margin-right: 20px;
    margin-bottom: 30px;
}

.search .searchbtn {
    margin-left: 10px;
    width: 10%;
    background-color: #00381C;

}

.search .searchbtn i {
    color: #D1A34F;
}

.search input {
    vertical-align: middle;
    padding: 10px;
}

.search input {
    height: 100%;
    border-radius: 5px;
    border: 2px solid rgb(187, 187, 187);
}

.content {
    margin-left: 1px;
    margin-right: 1px;
}

.table {
    border-collapse: separate;
    border-spacing: 0px 2px;
}

.table td:not(:first-child) {
    padding: 10px;
    border-left: 2px solid rgb(187, 187, 187);
}

.table td {
    background-color: rgb(230, 230, 230);
}

.table .selected td {
    background-color: #d6f1d2;
}

.table .selected .fa-circle {
    color: #10b97e;
}

.table td:first-child {
    font-size: 20px;
    font-weight: 600;
    vertical-align: middle;
}

.table td:first-child input {
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 1px solid rgb(187, 187, 187);
    vertical-align: middle;
}

.table td:first-child span {
    display: inline-block;
    vertical-align: middle;
}

.delete {
    font-size: 25px;
    text-align: center;
    vertical-align: middle;
}

.delete i {
    color: gray;
}

.date {
    vertical-align: middle;
    text-align: center;
    align-items: center;
}

.delete-all {
    margin-top: 10px;
}

.delete-all a {
    text-decoration: none;
}
</style>