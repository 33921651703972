<template>
    <div>
        <div class="container-fluid main search my-3">
            <div class="row title">
                <div class="col-11">
                    Recherche
                </div>
                <div class="col-1 d-flex justify-content-end align-items-center">
                    <i class="fa-solid fa-minus"></i>
                </div>
            </div>
            <div class="row mt-2 mx-2">
                <div class="col-12">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste quisquam iure tempore quia ipsum a!
                </div>
            </div>
            <div class="row mx-2">
                <div class="col-md-9 mt-3">
                    <input type="text" class="w-100" placeholder="type your search here" ref="searchInput"
                        id="searchInput">
                </div>
                <div class="col-md-auto mt-3 d-flex justify-content-end">
                    <button @click="updateSearchQuery" id="search" class="btn">
                        <i class="fa-solid fa-search"></i>
                        LANCER LA RECHERCHE
                    </button>
                </div>
            </div>
            <div class="row mt-3 mx-2">
                <div class="col-md-auto d-flex align-items-center">
                    <input type="radio" class="radio" name="a"><label class="mx-2" for="a">
                        Recherche approchee
                    </label>
                </div>
                <div class="col-md-auto d-flex align-items-center">
                    <input type="radio" class="radio" name="a"><label class="mx-2" for="a">
                        Recherche exacte
                    </label>
                </div>
            </div>
        </div>
        <div class="container-fluid my-4 mx-2">
            <div class="row">
                <div class="col-lg-3 filter-section border p-4">
                    <div class="row filter-header p-2 mt-2">
                        <div class="col-sm-1">
                            <i class="fa-solid fa-bars"></i>
                        </div>
                        <div class="col-sm-11">
                            Filters
                        </div>
                    </div>
                    <h6 class="mt-2">Categories</h6>

                    <ul class="list-unstyled ps-0" id="menu">
                        <li class="mb-1">
                            <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0"
                                @click="toggleDropdown" :aria-expanded="isDropdownOpen.toString()"
                                :class="{ 'collapsed': !isDropdownOpen }">
                                Service
                            </button>
                            <div class="collapse" :class="{ show: isDropdownOpen }" id="pServiceCategories">
                                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                    <li>
                                        <a class="link-dark d-inline-flex text-decoration-none rounded"
                                            @click="selectCategory(null)">
                                            All Categories
                                        </a>
                                    </li>
                                    <li v-for="(category, index) in services" :key="index">
                                        <a class="link-dark d-inline-flex text-decoration-none rounded"
                                            @click="selectCategory(category.id)">
                                            {{ category.name }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="mb-1">
                            <button class="btn d-inline-flex align-items-center rounded border-0 collapsed"
                                onclick="location.href='/search/ptravaux';">
                                Travaux
                            </button>
                        </li>
                        <li class="mb-1">
                            <button class="btn d-inline-flex align-items-center rounded border-0 collapsed"
                                onclick="location.href='/search/fourniture';">
                                Fournitures
                            </button>
                        </li>
                    </ul>
                    <h6>Pays</h6>
                    <select class="form-select" v-model="selectedCountry">
                        <option selected value="all">Tout</option>
                        <option v-for="(country, index) in countries" :key="index" :value="country">{{ country }}
                        </option>
                    </select>

                    <h6>Order</h6>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="priceSort" id="priceAsc" checked>
                        <label class="form-check-label" for="priceAsc">Ordre croissant</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="priceSort" id="priceDesc">
                        <label class="form-check-label" for="priceDesc">Ordre décroissant</label>
                    </div>

                    <h6>Type</h6>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="typeAll" checked>
                        <label class="form-check-label" for="typeAll">Tout</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="typePromo">
                        <label class="form-check-label" for="typePromo">Promo</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="typeGroup">
                        <label class="form-check-label" for="typeGroup">Achats groupés</label>
                    </div>
                </div>
                <div class="col-lg-9 border p-3" id="main">
                    <div class="row  text-black p-2" id="title" style="background-color:#E3E3E3 ;">
                        <div class="col">
                            <h5>
                                Prestataire Service
                            </h5>
                        </div>
                    </div>
                    <div class="row searchproduct">
                        <div class="col-xl-4 mb-4" v-for="(item, index) in filtredPServices" :key="index">
                            <div class="card m-3">
                                <img :src="require('../../assets/uploads/' + item.logo + '.png')" :alt="item.name">

                                <p class="card-title">{{ item.name }}</p>
                                <p class="card-text">{{ item.description }}</p>

                                <div class="row more mx-2 mb-2 d-flex justify-content-between">
                                    <div class="col-xxl-auto d-flex align-items-center">
                                        <i class="fa-regular fa-building"></i>
                                        <span class="m-2">{{ truncatedActivity(item.activity) }}</span>
                                    </div>
                                    <div class="col-xxl-auto d-flex align-items-center">
                                        <i class="fa-solid fa-flag"></i>
                                        <span class="m-2">{{ item.country }}</span>
                                    </div>
                                    <div class="col-xxl-auto d-flex align-items-center justify-content-end">
                                        <button @click="navigateToPServicePage(item.id)"><i
                                                class="fa-solid fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import apiService from '../../tools/apiService.js'

export default {
    props: {
        selectedCategoryIdParams: {
            type: Number,
            required: false
        },
    },
    data() {
        return {
            isDropdownOpen: false,
            countries: [],
            pService: [],
            filtredPServices: [],
            tCategories: [],
            services: [],

            selectedCountry: "all",

            selectedCategoryId: null,
            searchQuery: this.$route.query.search || '',
        }
    },
    mounted() {
        this.fetchCategories();
        this.fetchPService();
        document.getElementById("searchInput").value = this.$route.query.search || ''
    },
    watch: {
        selectedCountry() {
            this.filterPService();
        },
        selectedCategoryId() {
            this.filterPService();
        },
        searchQuery() {
            this.filterPService();
        },
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen; // Toggle the state
        },
        truncatedActivity(activity) {
            if (activity.length > 20) {
                return activity.substring(0, 20) + '...';
            }
            return activity;
        },
        navigateToPServicePage(pServiceID) {
            this.$router.push(`/pservice/${pServiceID}`);
        },
        fetchCategories() {
            apiService.servicesAPI.getAllServices()
                .then(response => {
                    this.services = response.data

                })
                .catch(error => {
                    console.error('Error fetching service categories:', error);
                });
        },
        fetchPService() {
            apiService.servicesAPI.getAllPService()
                .then(response => {
                    this.pService = response.data

                    if (this.pService.length > 0) {
                        // Extract unique company names and countries
                        this.countries = [...new Set(this.pService.map(pTrav => pTrav.country))];
                    }
                    this.filterPService();
                })
                .catch(error => {
                    console.error('Error fetching p-Service:', error);
                });
        },
        filterPService() {
            this.filtredPServices = this.pService.filter((pService) => {
                const matchesCountry =
                    this.selectedCountry === "all" ||
                    pService.country === this.selectedCountry;


                const matchesCategory = !this.selectedCategoryId || this.services.some(service => service.id_prestataire_service === pService.id && service.id_prestataire_service === this.selectedCategoryId);

                const matchesSearchQuery =
                    !this.searchQuery || pService.name.toLowerCase().includes(this.searchQuery.toLowerCase());

                return (
                    matchesCountry &&
                    matchesCategory &&
                    matchesSearchQuery
                );
            });
        },
        updateSearchQuery() {
            this.searchQuery = this.$refs.searchInput.value;
            this.filterPService();
        },
        selectCategory(categoryId) {
            this.selectedCategoryId = categoryId;
        },
    }

}
</script>

<style>
.dropdown-menu {
    position: static;
    float: none;
}

.dropdown-menu .dropdown-menu {
    margin-left: 20px;
    top: 0;
}

:root {
    margin: 20px;
}

.main {
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
    border-top: 0px;
}


.search input {
    height: 100%;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
}

#main {
    border-radius: 15px;
}

#title {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#search.btn {
    background-color: #00381C;
    color: #D1A34F;
    font-size: 20px;
    font-weight: 500;
    padding-left: 30px;
    padding-right: 30px;
}

#search.btn i {
    margin-right: 10px;
}


.main .title {
    color: #00381C;
    background-color: #D1A34F;
    padding: 10px;
    padding-bottom: 7px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 25px;
    font-weight: 600;
}

.title .col-1 i {
    color: white;
    font-size: 50px;
}

.btn-toggle:hover,
.btn-toggle:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #d2f4ea;
}

.btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
    color: rgba(0, 0, 0, .85);
}

.btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
}

.btn-toggle-nav a {
    padding: .1875rem .5rem;
    margin-top: .125rem;
    margin-left: 1.25rem;
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
    background-color: #d2f4ea;
}

.scrollarea {
    overflow-y: auto;
}

.card-body p {
    font-size: 14px;
}

.card-footer {
    background-color: #fff;
    border-top: none;
}

.card-footer i {
    margin-right: 5px;
}

.promo-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px;
}

.filter-section {
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
}

.filter-section h6 {
    margin-top: 20px;
    font-size: 16px;
}

.filter-section .form-control,
.filter-section .form-check-label {
    font-size: 14px;
}

.filter-section .form-range {
    width: 100%;
}

.filter-header {
    background-color: rgb(245, 245, 245);
}

.promo-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px;
}

.card-footer {
    background-color: #fff;
    border-top: none;
}

.card-footer i {
    margin-right: 5px;
}

.searchproduct .card-title {
    font-weight: 600;
    font-size: 20px;
}

.searchproduct .card-title,
.searchproduct .card-text {
    margin-left: 10px;
    margin-right: 10px;
}

.more {
    font-size: 15px;
}

.more i,
.achat button i {
    color: #D1A34F;
    font-size: 20px;
}

.more .col-3 i,
.more .col-5 i {
    margin-right: 10px;
}

.more button,
.achat button {
    border: 1px solid #D1A34F;
    border-radius: 5px;
    background-color: transparent;
    padding: 5px;
}

.more button i,
.achat button i {
    width: 20px;
    height: 20px;
}
</style>