<template>
    <div class="container-fluid my-2 main promotions">
        <div class="row title">
            <div class="col-lg-2">
                Promotions
            </div>
            <div class="col-lg-9">
                <div class="row">
                    <div class="col d-flex justify-content-center align-items-center">
                        <div class="country">
                            <input type="radio" name="country" id="" value="afrique">AFRIQUE
                        </div>
                    </div>
                    <div class="col d-flex justify-content-center align-items-center">
                        <div class="country">
                            <input type="radio" name="country" id="" value="h-afrique">HORS AFRIQUE
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 d-flex justify-content-end align-items-center">
                <i class="fa-solid fa-minus"></i>
            </div>
        </div>

        <div class="row mt-2 mx-4 promo-products">
            <Carousel :breakpoints="breakpoints" v-bind="settings">
                <Slide v-for="(promoProduct, index) in promoProducts" :key="index" class="col-sm-3 m-2">
                    <div class="carousel__item card d-flex justify-content-between">
                        <img :src="require('../../assets/uploads/' + promoProduct.image + '.png')"
                            :alt="promoProduct.name">
                        <p class="card-title">{{ promoProduct.name }}</p>
                        <p class="card-text">
                            {{ promoProduct.description }}
                        </p>
                        <div class="row more mx-2 mb-2">
                            <div class="col-4 d-flex align-items-center">
                                <i class="fa-solid fa-location-dot mx-2"></i>{{promoProduct.country}}
                            </div>
                            <div class="col-7 d-flex align-items-center justify-content-start">
                                <i class="fa-solid fa-calendar-days mx-2"></i>23, JUILLET 2024
                            </div>
                            <div class="col-1 d-flex align-items-center justify-content-end">
                                <button @click="navigateToFourniturePage(promoProduct.id)"><i class="fa-solid fa-chevron-right"></i></button>
                            </div>
                        </div>
                    </div>
                </Slide>
                <template #addons>
                    <Navigation />
                </template>
            </Carousel>
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import apiService from '../../tools/apiService.js'

export default {
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            promoProducts: [],
            settings: {
                itemsToShow: 1,
                snapAlign: 'start',
            },
            breakpoints: {
                // 800px and up
                800: {
                    itemsToShow: 1.5,
                    snapAlign: 'center',
                },
                // 1000px and up
                1000: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
                // 1200px and up
                1200: {
                    itemsToShow: 2.5,
                    snapAlign: 'start',
                },
                // 1400px and up
                1400: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
                // 1024 and up
                1500: {
                    itemsToShow: 4,
                    snapAlign: 'start',
                },
            },
        };
    },
    mounted() {
        this.fetchPromoFournitures();
    },
    methods: {
        navigateToFourniturePage(fournitureID) {
            this.$router.push(`/fourniture/${fournitureID}`);
        },
        fetchPromoFournitures() {
            apiService.fournituresAPI.getAllFournitures()
                .then(response => {
                    this.promoProducts = response.data
                    console.log(this.promoProducts)
                })
                .catch(error => {
                    console.error('Error fetching f_categories:', error);
                });
        }
    }
};
</script>

<style scoped>
.promotions .card-title {
    font-weight: 600;
    font-size: 20px;
}

.promotions .card-title,
.promotions .card-text {
    margin-left: 10px;
    margin-right: 10px;
}

.more {
    font-size: 15px;
}

.more i {
    color: #D1A34F;
    font-size: 20px;
}

.more .col-3 i,
.more .col-5 i {
    margin-right: 10px;
}

.more button {
    border: 1px solid #D1A34F;
    border-radius: 5px;
    background-color: transparent;
    padding: 5px;
}

.more button i {
    width: 20px;
    height: 20px;
}

.country {
    color: white;
    font-size: 20px;
}

.country input {
    transform: scale(1.5);
    margin-right: 10px;
}

.promotions .card-title {
    font-weight: 600;
    font-size: 20px;
}

.promotions .card-title,
.promotions .card-text {
    margin-left: 10px;
    margin-right: 10px;
}

.promo-products .carousel__slide {
    height: 500px;
}
.card{
    height: 100%;
}
.card img {
    height: 100%;
}
</style>