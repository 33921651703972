<template>
    <div >
        <NavBarComponent />
        <div class="mt-4">
            <NavBarRootesComponent />
        </div>
        <div class="mt-4 mx-3">
            <CourrielComponents />
        </div>
        <FooterComponent />
    </div>
</template>

<script>
// Importing the components
import NavBarComponent from '../components/NavBarComponent.vue'
import NavBarRootesComponent from '../components/NavBarRootesComponent.vue'
import FooterComponent from '../components/FooterComponent.vue'
import CourrielComponents from '../components/courriel/CourrielComponents.vue'

export default {
    components: {
        NavBarComponent,
        NavBarRootesComponent,
        FooterComponent,
        CourrielComponents,
    },
   
}
</script>

<style scoped>


</style>