<template>
    <div>
        <div class="container-fluid main search my-3">
            <div class="row title">
                <div class="col-11">
                    Recherche
                </div>
                <div class="col-1 d-flex justify-content-end align-items-center">
                    <i class="fa-solid fa-minus"></i>
                </div>
            </div>
            <div class="row mt-2 mx-2">
                <div class="col-12">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste quisquam iure tempore quia ipsum a!
                </div>
            </div>
            <div class="row mx-2">
                <div class="col-md-9 mt-3">
                    <input type="text" class="w-100" placeholder="type your search here" ref="searchInput"
                        id="searchInput">
                </div>
                <div class="col-md-auto mt-3 d-flex justify-content-end">
                    <button @click="updateSearchQuery" id="search" class="btn">
                        <i class="fa-solid fa-search"></i>
                        LANCER LA RECHERCHE
                    </button>
                </div>
            </div>
            <div class="row mt-3 mx-2">
                <div class="col-md-auto d-flex align-items-center">
                    <input type="radio" class="radio" name="a"><label class="mx-2" for="a">
                        Recherche approchee
                    </label>
                </div>
                <div class="col-md-auto d-flex align-items-center">
                    <input type="radio" class="radio" name="a"><label class="mx-2" for="a">
                        Recherche exacte
                    </label>
                </div>
            </div>
        </div>
        <div class="container-fluid my-4 mx-2">
            <div class="row">
                <div class="col-lg-3 filter-section border p-4">
                    <div class="row filter-header p-2 mt-2">
                        <div class="col-sm-1">
                            <i class="fa-solid fa-bars"></i>
                        </div>
                        <div class="col-sm-11">
                            Filters
                        </div>
                    </div>
                    <h6 class="mt-2">Categories</h6>

                    <ul class="list-unstyled ps-0" id="menu">
                        <li class="mb-1">
                            <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0"
                                @click="toggleDropdown" :aria-expanded="isDropdownOpen.toString()"
                                :class="{ 'collapsed': !isDropdownOpen }">
                                Fournitures
                            </button>
                            <div class="collapse" :class="{ show: isDropdownOpen }" id="fournitureCategories">
                                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                    <li>
                                        <a class="link-dark d-inline-flex text-decoration-none rounded"
                                            @click="selectCategory(null)">
                                            All Categories
                                        </a>
                                    </li>
                                    <li v-for="(category, index) in fCategories" :key="index">
                                        <a class="link-dark d-inline-flex text-decoration-none rounded"
                                            @click="selectCategory(category.id)">
                                            {{ category.name }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="mb-1">
                            <button class="btn d-inline-flex align-items-center rounded border-0 collapsed"
                                onclick="location.href='/search/pservice';">
                                Service
                            </button>
                        </li>
                        <li class="mb-1">
                            <button class="btn d-inline-flex align-items-center rounded border-0 collapsed"
                                onclick="location.href='/search/ptravaux';">
                                Travaux
                            </button>
                        </li>
                    </ul>

                    <h6>Pays</h6>
                    <select class="form-select" v-model="selectedCountry">
                        <option selected value="all">Tout</option>
                        <option v-for="(country, index) in countries" :key="index" :value="country">{{ country }}
                        </option>
                    </select>
                    <!-- <select class="form-select">
                        <option selected value="all">Tout</option>
                        <option v-for="(country, index) in countries" :key="index" :value="country">{{ country }}
                        </option>
                    </select> -->

                    <h6>Order</h6>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="priceSort" id="priceAsc" checked>
                        <label class="form-check-label" for="priceAsc">Ordre croissant</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="priceSort" id="priceDesc">
                        <label class="form-check-label" for="priceDesc">Ordre décroissant</label>
                    </div>

                    <h6 class="my-4">Prix / Budget</h6>

                    <div v-if="priceMin != null">
                        <CustomMinMaxSlider :min="0" :max="initialPriceMax" :step="1" :minValue="priceMin"
                            :maxValue="priceMax" @update:minValue="handleMinValueUpdate"
                            @update:maxValue="handleMaxValueUpdate" />

                    </div>
                    <h6>Type</h6>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="typeAll" checked>
                        <label class="form-check-label" for="typeAll">Tout</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="typePromo">
                        <label class="form-check-label" for="typePromo">Promo</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="typeGroup">
                        <label class="form-check-label" for="typeGroup">Achats groupés</label>
                    </div>
                </div>
                <div class="col-lg-9 border p-3" id="main">
                    <div class="row  text-black p-2" id="title" style="background-color:#E3E3E3 ;">
                        <div class="col">
                            <h5>
                                Fourniture &gt; {{}}
                            </h5>
                        </div>
                    </div>
                    <div class="row mb-3 mt-2">
                        <div class="col-md-6">
                            <!-- <select class="form-select">
                                <option selected>All Suppliers</option>
                                <option v-for="(fournisseur, index) in fournisseurs" :key="index" :value="fournisseur">
                                    {{ fournisseur }}</option>
                            </select> -->

                            <select class="form-select" v-model="selectedFournisseur">
                                <option selected value="all">Tous les fournisseurs</option>
                                <option v-for="(fournisseur, index) in fournisseurs" :key="index" :value="fournisseur">
                                    {{ fournisseur }}</option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <select class="form-select">
                                <option selected>Models</option>
                                <!-- Add other model options here -->
                            </select>
                        </div>
                    </div>
                    <div class="row searchproduct">
                        <div class="col-xl-4 mb-4" v-for="(item, index) in filteredFournitures" :key="index">
                            <div class="card m-3">
                                <img :src="require('../../assets/uploads/' + item.image + '.png')" :alt="item.name">

                                <p class="card-title">{{ item.name }}</p>
                                <p class="card-text">{{ item.description }}</p>
                                <div class="row more mx-2 mb-2 d-flex justify-content-between">
                                    <div class="col-xxl-auto d-flex align-items-center">
                                        <i class="fa-regular fa-user"></i>
                                        <span class="m-2">{{ item.company_name }}</span>
                                    </div>
                                    <div class="col-xxl-auto d-flex align-items-center">
                                        <i class="fa-solid fa-flag"></i>
                                        <span class="m-2">{{ item.country }}</span>
                                    </div>
                                    <div class="col-xxl-auto d-flex align-items-center justify-content-end">
                                        <button @click="navigateToFourniturePage(item.id)"><i
                                                class="fa-solid fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import CustomMinMaxSlider from "../CustomeMinMaxSlider.vue";
import apiService from '../../tools/apiService.js'

export default {
    props: {
        selectedCategoryIdParams: {
            type: Number,
            required: false
        },
    },
    components: {
        CustomMinMaxSlider
    },
    data() {
        return {
            priceMin: null,
            initialPriceMin: null,
            initialPriceMax: null,
            priceMax: null,
            countries: [],
            fournisseur: [],
            fournitures: [],
            filteredFournitures: [],
            fCategories: [],

            selectedCountry: "all",
            selectedFournisseur: "all",
            selectedCategoryId: null,
            searchQuery: this.$route.query.search || '',
            isDropdownOpen: false,
        }
    },
    mounted() {
        this.fetchCategories();
        this.fetchFournitures();
        document.getElementById("searchInput").value = this.$route.query.search || ''
    },
    watch: {
        selectedCountry() {
            this.filterFournitures();
        },
        selectedFournisseur() {
            this.filterFournitures();
        },
        selectedCategoryId() {
            this.filterFournitures();
        },
        priceMin() {
            this.filterFournitures();
        },
        priceMax() {
            this.filterFournitures();
        },
        searchQuery() {
            this.filterFournitures();
        },
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen; // Toggle the state
        },
        navigateToFourniturePage(fournitureID) {
            this.$router.push(`/fourniture/${fournitureID}`);
        },
        fetchCategories() {
            apiService.fournituresAPI.getAllCategories()
                .then(response => {
                    this.fCategories = response.data
                    // this.createMenu(this.fCategories);

                })
                .catch(error => {
                    console.error('Error fetching f_categories:', error);
                });
        },
        fetchFournitures() {
            apiService.fournituresAPI.getAllFournitures()
                .then(response => {
                    this.fournitures = response.data

                    if (this.fournitures.length > 0) {
                        // Extract prices and find min and max
                        const prices = this.fournitures.map(fourniture => parseFloat(fourniture.price));

                        // this.priceMin = Math.min(...prices);
                        this.priceMin = 0;
                        this.priceMax = Math.max(...prices);
                        this.initialPriceMin = Math.min(...prices);
                        this.initialPriceMax = Math.max(...prices);
                        // Extract unique company names and countries
                        this.fournisseurs = [...new Set(this.fournitures.map(fourniture => fourniture.company_name))];
                        this.countries = [...new Set(this.fournitures.map(fourniture => fourniture.country))];

                    }
                    this.filterFournitures();
                })
                .catch(error => {
                    console.error('Error fetching f_categories:', error);
                });
        },
        filterFournitures() {
            this.filteredFournitures = this.fournitures.filter((fourniture) => {
                const matchesCountry =
                    this.selectedCountry === "all" ||
                    fourniture.country === this.selectedCountry;
                const matchesFournisseur =
                    this.selectedFournisseur === "all" ||
                    fourniture.company_name === this.selectedFournisseur;
                const matchesCategory =
                    !this.selectedCategoryId ||
                    fourniture.category_id === this.selectedCategoryId;
                const matchesPriceMin =
                    !this.priceMin || parseFloat(fourniture.price) >= this.priceMin;
                const matchesPriceMax =
                    !this.priceMax || parseFloat(fourniture.price) <= this.priceMax;
                const matchesSearchQuery =
                    !this.searchQuery || fourniture.name.toLowerCase().includes(this.searchQuery.toLowerCase());

                return (
                    matchesCountry &&
                    matchesFournisseur &&
                    matchesCategory &&
                    matchesPriceMin &&
                    matchesPriceMax &&
                    matchesSearchQuery
                );
            });
        },
        updateSearchQuery() {
            this.searchQuery = this.$refs.searchInput.value;
            this.filterFournitures();
        },
        selectCategory(categoryId) {
            this.selectedCategoryId = categoryId;
        },
        handleMinValueUpdate(newMinValue) {
            this.priceMin = newMinValue;
        },
        handleMaxValueUpdate(newMaxValue) {
            this.priceMax = newMaxValue;
        },
    }

}
</script>

<style scoped>
.dropdown-menu {
    position: static;
    float: none;
}

.dropdown-menu .dropdown-menu {
    margin-left: 20px;
    top: 0;
}

:root {
    margin: 20px;
}

.main {
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
    border-top: 0px;
}


.search input {
    height: 100%;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
}

#main {
    border-radius: 15px;
}

#title {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#search.btn {
    background-color: #00381C;
    color: #D1A34F;
    font-size: 20px;
    font-weight: 500;
    padding-left: 30px;
    padding-right: 30px;
}

#search.btn i {
    margin-right: 10px;
}


.main .title {
    color: #00381C;
    background-color: #D1A34F;
    padding: 10px;
    padding-bottom: 7px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 25px;
    font-weight: 600;
}

.title .col-1 i {
    color: white;
    font-size: 50px;
}

.btn-toggle:hover,
.btn-toggle:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #d2f4ea;
}

.btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
    color: rgba(0, 0, 0, .85);
}

.btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
}

.btn-toggle-nav a {
    padding: .1875rem .5rem;
    margin-top: .125rem;
    margin-left: 1.25rem;
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
    background-color: #d2f4ea;
}

.scrollarea {
    overflow-y: auto;
}

.card-body p {
    font-size: 14px;
}

.card-footer {
    background-color: #fff;
    border-top: none;
}

.card-footer i {
    margin-right: 5px;
}

.promo-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px;
}

.filter-section {
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
}

.filter-section h6 {
    margin-top: 20px;
    font-size: 16px;
}

.filter-section .form-control,
.filter-section .form-check-label {
    font-size: 14px;
}

.filter-section .form-range {
    width: 100%;
}

.filter-header {
    background-color: rgb(245, 245, 245);
}

.promo-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px;
}

.card {
    height: 600px !important;
    display: flex;
    justify-content: space-between;
}

.card-footer {
    background-color: #fff;
    border-top: none;
}

.card-footer i {
    margin-right: 5px;
}

.searchproduct .card-title {
    font-weight: 600;
    font-size: 20px;
}

.searchproduct .card-title,
.searchproduct .card-text {
    margin-left: 10px;
    margin-right: 10px;
}

.more {
    font-size: 15px;
}

.more i,
.achat button i {
    color: #D1A34F;
    font-size: 20px;
}

.more .col-3 i,
.more .col-5 i {
    margin-right: 10px;
}

.more button,
.achat button {
    border: 1px solid #D1A34F;
    border-radius: 5px;
    background-color: transparent;
    padding: 5px;
}

.more button i,
.achat button i {
    width: 20px;
    height: 20px;
}
</style>