<template>
    <div>
        <div class="container-fluid main">
            <div class="row title">
                <div class="col-11">
                    <i class="fa-solid fa-envelope"></i>
                    Courriel
                </div>
                <div class="col-1 d-flex justify-content-end align-items-center">
                    <i class="fa-solid fa-minus"></i>
                </div>
            </div>
            <!-- <div class="row buttons">
                <div class="col">
                    <button class="btn receive"><i class="fa-solid fa-angles-left"></i>Boite de reception</button>
                </div>
                <div class="col">
                    <button class="btn send"><i class="fa-solid fa-angles-right"></i>Boite d'envoi</button>
                </div>
                <div class="col">
                    <button class="btn newmessage"><i class="fa-solid fa-plus"></i>Nouveau message</button>
                </div>
                <div class="col search">
                    <input type="text" placeholder="type your search here" class="w-75">
                    <button class="btn searchbtn"><i class="fa-solid fa-magnifying-glass"></i></button>
                </div>
            </div> -->
            <div class="row form">
                <div class="col-sm">
                    <h4 class="mb-4">Nouveau message</h4>
                    <div class="col-sm">
                        <input type="email" name="" id="" class="w-100 mb-3" placeholder="a">
                        <input type="text" name="" id="" class="w-100 mb-3" placeholder="OBJET">
                        <textarea name="" id="" rows="10" class="w-100 p-2" placeholder="type message"></textarea>
                    </div>
                </div>
            </div>
            <div class="row submit-edit">
                <div class="col-sm">
                    <i class="fa-solid fa-reply"></i>
                    <i class="fa-solid fa-share"></i>
                    <i class="fa-regular fa-underline"></i>
                    <i class="fa-solid fa-italic"></i>
                </div>
                <div class="col-sm-2">
                    <button class="btn btn-primary"><i class="fa-solid fa-paper-plane"></i> Envoyer</button>
                </div>
            </div>
        </div>
        <div class="back container-fluid d-flex justify-content-end">
            <div class="row">
                <button class="btn btn-primary"><i class="fa-solid fa-circle-chevron-left"></i> RETOUR</button>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },
    methods: {
    }
};
</script>

<style scoped>
   p,
   h6 {
       margin: 0;
   }

   .main {
       border-radius: 15px;
       padding-left: 10px;
       padding-right: 10px;
       padding-bottom: 80px;
       border: 1px solid gray;
       border-top: 0px;
       margin-bottom: 30px;
   }

   .main .title {
       color: #00381C;
       background-color: #D1A34F;
       padding: 10px;
       padding-bottom: 7px;
       border-top-right-radius: 10px;
       border-top-left-radius: 10px;
       font-size: 25px;
       font-weight: 600;
   }

   .title .col-1 i {
       color: white;
       font-size: 50px;
   }

   /* .main .buttons {
       border-bottom: 1px solid gray;
   }

   .main .buttons .col {
       margin-top: 20px;
       margin-bottom: 20px;
   } 

   .buttons .btn {
       border: 1px solid rgb(187, 187, 187);
       font-size: 20px;
       font-weight: 300;
   }

   .buttons .btn i {
       margin-right: 10px;
   }

   .buttons .btn:not(.searchbtn) {
       width: 80%;
   }

   .buttons .receive {
       color: rgb(0, 94, 0);
   }

   .buttons .newmessage {
       background-color: rgb(30, 173, 30);
       color: white;
       font-weight: 500;
       text-decoration: underline;
   }

   .buttons .searchbtn {
       height: 100%;
       width: 10%;
       background-color: #00381C;
   }

   .buttons .searchbtn i {
       color: #D1A34F;
   }

   .buttons .search input {
       vertical-align: middle;
       padding: 10px;
   }

   .buttons .search input {
       height: 100%;
       border-radius: 5px;
       border: 2px solid rgb(187, 187, 187);
   }*/

   .form {
       color: #00381C;
       padding-top: 2%;
       padding-left: 2%;
       padding-right: 2%;
   }

   .form input,
   .form textarea {
       margin-top: 20px;
       border-radius: 5px;
       border: 2px solid rgb(201, 201, 201);
       padding: 7px;
   }

   .submit-edit {
       padding-top: 10px;
       padding-left: 2%;
       padding-right: 2%;
   }

   .col-sm-2 {
       display: flex;
       justify-content: end;
   }

   .col-sm-2 button {
       width: 60%;
       display: flex;
       align-items: center;
       justify-content: space-around;
       font-size: 20px;
   }

   .back button {
       padding-top: 5px;
       padding-bottom: 5px;
       padding-left: 15px;
       padding-right: 40px;
       display: flex;
       align-items: center;
       justify-content: space-around;
       font-size: 20px;
       background-color: #00381C;
       color: #D1A34F;
       margin-bottom: 15px;
   }

   .back button i {
       margin-right: 10px;
       color: #D1A34F;
   }
</style>