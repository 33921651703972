import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Dashboard from '../views/DashboardPage.vue'
import AppelOffre from '../views/AppelOffrePage.vue'
import Courriel from '../views/CourrielPage.vue'
import Login from '../views/LoginPage.vue'
import SearchFourniturePage from '../views/SearchFourniturePage.vue'
import SearchPServicePage from '../views/SearchPServicePage.vue'
import SearchPTravauxPage from '../views/SearchPTravauxPage.vue'
import Notification from '../views/NotificationPage.vue'
import PService from '../views/PServicePage.vue'
import Product from '../views/ProductPage.vue'
import PTravaux from '../views/PTravauxPage.vue'
import { authGuard } from '../store'
import FournitureProductPage from '../views/FournitureProductPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/Login',
    name: 'login',
    component: Login,
    // beforeEnter: authGuard // Apply authGuard to protect the route
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: authGuard // Apply authGuard to protect the route
  },
  {
    path: '/appeloffre',
    name: 'appeloffre',
    component: AppelOffre,
    beforeEnter: authGuard
  },
  {
    path: '/courriel',
    name: 'courriel',
    component: Courriel,
    beforeEnter: authGuard
  },
  {
    path: '/search/ptravaux',
    name: 'search-p-t',
    component: SearchPTravauxPage,
    beforeEnter: authGuard
  },
  {
    path: '/search/pservice',
    name: 'search-p-s',
    component: SearchPServicePage,
    beforeEnter: authGuard
  },
  {
    path: '/search/fourniture',
    name: 'search-f',
    component: SearchFourniturePage,
    beforeEnter: authGuard
  },
  {
    path: '/fourniture/:id',
    name: 'fourniture',
    component: FournitureProductPage,
    beforeEnter: authGuard
  },
  {
    path: '/pservice/:id',
    name: 'pservice',
    component: PService,
    beforeEnter: authGuard
  },
  {
    path: '/ptravaux/:id',
    name: 'ptravaux',
    component: PTravaux,
    beforeEnter: authGuard
  },
  {
    path: '/product/2517',
    name: 'product',
    component: Product,
    beforeEnter: authGuard
  },
  {
    path: '/notification',
    name: 'notification',
    component: Notification,
    beforeEnter: authGuard
  },
  // {
  //   path: '/service',
  //   name: 'service',
  //   component: Service,
  //   beforeEnter: authGuard
  // },
  // {
  //   path: '/Product',
  //   name: 'Product',
  //   component: Product,
  // },
  // {
  //   path: '/travaux',
  //   name: 'travaux',
  //   component: Travaux,
  //   beforeEnter: authGuard
  // },

]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router;
