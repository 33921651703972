<template>
    <footer class="footer">
        <div class="footer-wrapper">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="footer-element">
                            <img src="../assets/svg/logo.svg" alt="" class="logo" />
                            <p>
                                African Union for Real Estate AURE a été créé le 28 juin 2022 à Casablanca, Maroc est
                                composée d'entités nationales représentatives des promoteurs immobiliers dans les pays
                                Africains qui sont membres de l'organisation des Nations Unies afin d'assurer une bonne
                                coordination dans le secteur de l'immobilier interafricain.
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="footer-element">
                            <h3>CONTACTS</h3>
                            <p>Villa 82, SINDIBAD, Av. du Golf Arabique, Casablanca.</p>
                            <p>+212 5 20 38 14 10</p>
                            <p>+212 5 22 98 59 79</p>
                            <p>contact@aure.property</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="footer-element">
                            <h3>NAVIGATION</h3>
                            <ul>
                                <li><router-link to="/">Home</router-link></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="footer-element">
                            <h3>NEWSLETTER</h3>
                            <p class="mb-2">Pour plus d'information, vous pouvez nous contactez directement en
                                remplissant le champ des informations suivant</p>
                            <div class="newsletter-from">
                                <input type="text" placeholder="Your email address" v-model="email" />
                                <button @click="subscribe">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="48" viewBox="0 0 60 48">
                                        <path id="ic_markunread_24px"
                                            d="M56,4H8a5.992,5.992,0,0,0-5.97,6L2,46a6.018,6.018,0,0,0,6,6H56a6.018,6.018,0,0,0,6-6V10A6.018,6.018,0,0,0,56,4Zm0,12L32,31,8,16V10L32,25,56,10Z"
                                            transform="translate(-2 -4)" fill="currentColor" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="green"></div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            email: ''
        };
    },
    methods: {
        subscribe() {
            // Perform subscription action here
            console.log('Subscribed with email:', this.email);
        }
    }
}
</script>

<style scoped>
@import '../assets/css/footer.css';
</style>