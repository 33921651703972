<template>
    <div>
        <NavBarComponent />
        <div class="mt-4">
            <NavBarRootesComponent />
        </div>
        <div class="m-3">

            <SearchComponent />
        </div>

        <FooterComponent />
    </div>
</template>

<script>
// Importing the components
import NavBarComponent from '../components/NavBarComponent.vue'
import NavBarRootesComponent from '../components/NavBarRootesComponent.vue'
import FooterComponent from '../components/FooterComponent.vue'
import SearchComponent from '../components/search/SearchTravaux.vue'


export default {
    components: {
        NavBarComponent,
        NavBarRootesComponent,
        SearchComponent,
        FooterComponent,
    },

}
</script>

<style scoped>
</style>