<template>
    <div class="container-fluid mt-4">
        <div class="row mb-3">
            <div class="col">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#">Fournitures</a></li>
                        <li class="breadcrumb-item"><a href="#">Plomberie</a></li>
                        <li class="breadcrumb-item"><a href="#">Sanitaire</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Robinetterie</li>
                    </ol>
                </nav>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="position-relative">
                    <img src="https://via.placeholder.com/400" class="product-image" alt="Product Image">
                    <div class="badge-promo">Promo</div>
                </div>
                <div class="d-flex flex-column mt-3">
                    <img src="https://via.placeholder.com/60" class="product-thumbnail" alt="Thumbnail">
                    <img src="https://via.placeholder.com/60" class="product-thumbnail" alt="Thumbnail">
                    <img src="https://via.placeholder.com/60" class="product-thumbnail" alt="Thumbnail">
                    <img src="https://via.placeholder.com/60" class="product-thumbnail" alt="Thumbnail">
                </div>
                <div class="mt-2">
                    <button class="btn btn-link">Ajouter aux Favoris</button>
                    <button class="btn btn-link">Voir Liste Des Favoris</button>
                </div>
            </div>
            <div class="col-md-6">
                <h5>Fournisseur X</h5>
                <h3>Robinet Mitigeur de Lavabo laiton cascade Finition Chromée céramique avec flexible</h3>
                <h4 class="text-success">95.60 USD</h4>
                <div class="mb-3">
                    <button class="btn btn-outline-secondary">Marque</button>
                    <button class="btn btn-outline-secondary">Lorem Ipsum</button>
                    <button class="btn btn-outline-secondary">Lorem Ipsum</button>
                    <button class="btn btn-outline-secondary">Lorem Ipsum</button>
                    <button class="btn btn-outline-secondary">Lorem Ipsum</button>
                </div>
                <button class="btn btn-primary mb-3">Contacter le Fournisseur</button>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="description-tab" data-bs-toggle="tab"
                            data-bs-target="#description" type="button" role="tab" aria-controls="description"
                            aria-selected="true">Description De Produit</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="availability-tab" data-bs-toggle="tab"
                            data-bs-target="#availability" type="button" role="tab" aria-controls="availability"
                            aria-selected="false">Disponibilité</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="delivery-tab" data-bs-toggle="tab" data-bs-target="#delivery"
                            type="button" role="tab" aria-controls="delivery" aria-selected="false">Livraison /
                            Paiement</button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="description" role="tabpanel"
                        aria-labelledby="description-tab">
                        <p>Couleur(s) : Chrom/Metal<br>Plus produit : Le volume et la température de l'eau peuvent être
                            facilement ajustés d'une seule main. Fourni avec les tuyau flexible<br>Dimensions : 185mm x
                            165mm<br>Matière : Céramique<br>Type de bec : Col de cygne<br>Economi d'eau :
                            Oui<br>Finition : Chromé<br>Tuyauterie / raccord fournis : Oui<br>Hauteur totale : 185
                            mm<br>Hauteur sous bec : 165 mm</p>
                    </div>
                    <div class="tab-pane fade" id="availability" role="tabpanel" aria-labelledby="availability-tab">
                        <p>Information sur la disponibilité...</p>
                    </div>
                    <div class="tab-pane fade" id="delivery" role="tabpanel" aria-labelledby="delivery-tab">
                        <p>Information sur la livraison et le paiement...</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col">
                <button class="btn btn-custom btn-block">Demander un Devis</button>
            </div>
        </div>
        <button class="btn btn-back">Retour</button>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },
    methods: {
    }
};
</script>

<style scoped>
.product-image {
    max-width: 100%;
    height: auto;
}

.product-thumbnail {
    max-width: 60px;
    height: auto;
    margin-bottom: 10px;
}

.badge-promo {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px;
}

.card-footer {
    background-color: #fff;
    border-top: none;
}

.card-footer i {
    margin-right: 5px;
}

.tab-content {
    margin-top: 20px;
}

.btn-custom {
    background-color: #004d00;
    color: white;
}

.btn-custom:hover {
    background-color: #003300;
    color: white;
}

.btn-back {
    background-color: #004d00;
    color: white;
    position: fixed;
    top: 10px;
    right: 10px;
}

.btn-back:hover {
    background-color: #003300;
    color: white;
}
</style>